<template>
  <div class="footer">
    <div class="df-width footer-box">
      <div class="footer-item-one">
        <el-image class="footer-item-logo" :src="logo" fit="cover"></el-image>
        <div class="footer-item-title">大悦科技</div>
      </div>
      <div class="footer-item-two">
        <div class="footer-item-info">
          <el-image class="footer-item-icon" :src="tel" fit="cover"></el-image>
          <div class="footer-item-label">咨询电话：</div>
          <div class="footer-item-cont footer-red">0756-6332001</div>
        </div>
        <div class="footer-item-info">
          <el-image class="footer-item-icon" :src="mail" fit="cover"></el-image>
          <div class="footer-item-label">电子邮箱：</div>
          <div class="footer-item-cont footer-red">dayuetech@163.com</div>
        </div>
        <div class="footer-item-info">
          <el-image class="footer-item-icon" :src="map" fit="cover"></el-image>
          <div class="footer-item-label">公司地址：</div>
          <div class="footer-item-cont">珠海市香洲区海滨南路LOFT新概念商务园A座5016室</div>
        </div>
        <div class="footer-web-info">
          Copyright© 2015-2022 珠海市大悦科技有限公司 all rights reserved
        </div>
        <div class="footer-web-info">
          ICP备案/许可证编号：<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备15040471号-1</a>
        </div>
      </div>
      <div class="footer-item-three">
        <div class="footer-banner-label">创造新价值</div>
        <div class="footer-banner-line"></div>
        <div class="footer-banner-label">成为客户最有价值的伙伴</div>
      </div>
      <div class="footer-item-four">
        <el-image class="footer-item-wechat" :src="wechat" fit="cover"></el-image>
        <div class="footer-wechat-label">微信公众号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      logo: require('@/assets/images/logo2.png'),
      tel: require('@/assets/images/tel.png'),
      mail: require('@/assets/images/mail.png'),
      map: require('@/assets/images/map.png'),
      wechat: require('@/assets/images/wechat.jpg')
    }
  }
}
</script>

<style scoped>
  .footer {
    height: 100%;
    background-color: #1E2028;
  }
  .footer-box {
    padding-top: 60px;
    display: flex;
  }
  .footer-item-one {
    margin-right: 70px;
  }
  .footer-item-logo {
    width: 166px;
    height: 40px;
  }
  .footer-item-title {
    width: 166px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    margin-top: 8px;
  }
  .footer-item-two {
    margin-right: 45px;
  }
  .footer-item-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .footer-item-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  .footer-item-label {
    color: #fff;
    font-size: var(--font-size);
    margin-right: 2px;
  }
  .footer-item-cont {
    font-size: var(--font-size);
    color: #fff;
  }
  .footer-red {
    color: var(--default-color)
  }
  .footer-web-info {
    font-size: var(--font-size);
    color: #B3B3B3;
    margin-bottom: 10px;
  }
  .footer-web-info a {
    color: #B3B3B3 !important;
  }
  .footer-banner-label {
    font-size: 24px;
    color: #B3B3B3;
  }
  .footer-banner-line {
    width: 87px;
    height: 2px;
    background-color: var(--default-color);
    margin: 10px 0 20px;
  }
  .footer-item-four {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
  }
  .footer-item-wechat {
    width: 100px;
    height: 100px;
  }
  .footer-wechat-label {
    width: 100px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
  }
</style>
