<template>
  <div class="header">
    <div class="header-box df-width">
      <a class="header-logo-link" href="/index.html"><el-image :src="logo" class="header-logo" lazy fit="cover"></el-image></a>
      <div class="header-nav">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          text-color="#333333"
          active-text-color="#C81125"
          background-color="#fff"
          :ellipsis="false"
        >
          <template v-for="(item, index) in menu" :key="index">
            <template v-if="item.childs.length > 0">
              <el-sub-menu :index="item.index">
                <template #title>{{ item.name }}</template>
<!--                :route="{path: ite.link}"-->
                <el-menu-item v-for="(ite, i) in item.childs" :key="i + '-' + index" :index="ite.index" @click="goToPage(ite.link)">{{ ite.name }}</el-menu-item>
              </el-sub-menu>
            </template>
            <template v-else>
<!--              :route="{path: item.link}"-->
              <el-menu-item :index="item.index" @click="goToPage(item.link)">{{ item.name }}</el-menu-item>
            </template>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      logo: require('@/assets/images/logo.png'),
      activeIndex: '1',
      menu: [
        {
          index: '1',
          name: '首页',
          link: '/index.html',
          childs: []
        },
        {
          index: '2',
          name: '公司概况',
          link: '/about.html',
          childs: []
        },
        {
          index: '3',
          name: '解决方案',
          childs: [
            {
              index: '3-1',
              name: '连锁企业管理系统',
              link: '/plan/first.html'
            },
            {
              index: '3-2',
              name: '协会组织管理系统',
              link: '/plan/second.html'
            },
            {
              index: '3-3',
              name: '教培行业管理系统',
              link: '/plan/third.html'
            }
            // {
            //   index: '3-4',
            //   name: '幼教平台“童悦乐园”',
            //   link: '/plan/fourth.html'
            // },
            // {
            //   index: '3-5',
            //   name: '网络系统建站服务',
            //   link: '/plan/fifth.html'
            // },
            // {
            //   index: '3-6',
            //   name: '安防系统',
            //   link: '/plan/sixth.html'
            // }
          ]
        },
        {
          index: '4',
          name: '经典案例',
          link: '/case.html',
          childs: []
        },
        {
          index: '5',
          name: '招贤纳士',
          link: '/recruit.html',
          childs: []
        },
        {
          index: '6',
          name: '联系我们',
          link: '/contact.html',
          childs: []
        }
      ]
    }
  },
  methods: {
    goToPage (e) {
      window.location.href = e
    }
  },
  mounted () {
    const nowPath = this.$route.path
    const name = this.$route.name
    const _this = this
    this.menu.map(v => {
      if (v.childs.length > 0) {
        v.childs.map(vv => {
          if (nowPath === vv.link) {
            _this.activeIndex = vv.index
          }
        })
      } else {
        if (nowPath === v.link) {
          _this.activeIndex = v.index
        }
      }
    })
    if (name === 'case_pro') {
      _this.activeIndex = '4'
    }
  }
}
</script>

<style scoped>
  .header {
    height: 80px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
  }

  .header-box {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .header-logo {
    width: 166px;
    height: 40px;
  }

  .header-logo-link {
    flex: 1
  }

  :deep(.el-image__inner) {
    width: 100%;
  }

  .header-nav {
    height: 100%;
  }

  .el-menu {
    border: none;
    height: 100%;
  }

  .el-menu-item {
    font-size: var(--font-nav-child);
  }

  :deep(.el-menu--horizontal>.el-sub-menu .el-sub-menu__title), :deep(.el-menu--horizontal>.el-menu-item) {
    height: 100%;
    font-size: var(--font-nav);
    font-weight: normal;
    width: 120px;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    font-weight: bold;
  }

  :deep(.el-menu--horizontal>.el-sub-menu .el-sub-menu__icon-arrow) {
    display: none;
  }
</style>
