<template>
  <div>
    <el-container>
      <el-header style="padding: 0;" height="80px">
        <Header/>
      </el-header>
      <el-main style="padding: 0;">
        <router-view></router-view>
      </el-main>
      <el-footer style="padding: 0;" height="240px">
        <Footer/>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Header from 'layout/Header'
import Footer from 'layout/Footer'

export default {
  name: 'Index',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>

</style>
