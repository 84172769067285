<template>
  <router-view/>
</template>

<style>
  @import 'assets/css/base.css';
  #app {
    height: 100%;
  }
</style>
