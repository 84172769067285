import { createRouter, createWebHistory } from 'vue-router'
import layout from 'layout/Index'

const routes = [
  {
    path: '/',
    redirect: '/index.html',
    component: layout,
    children: [
      {
        path: 'index.html',
        name: 'index',
        component: () => import('views/Index'),
        meta: {
          title: '珠海市大悦科技有限公司'
        }
      },
      {
        path: 'about.html',
        name: 'about',
        component: () => import('views/About'),
        meta: {
          title: '公司概况'
        }
      },
      {
        path: 'case.html',
        name: 'case',
        component: () => import('views/Case'),
        meta: {
          title: '经典案例'
        }
      },
      {
        path: 'case/:id([0-9]+).html',
        name: 'case_pro',
        component: () => import('views/ProDetail'),
        meta: {
          title: '案例详情'
        }
      },
      {
        path: 'recruit.html',
        name: 'recruit',
        component: () => import('views/Recruit'),
        meta: {
          title: '招贤纳士'
        }
      },
      {
        path: 'plan/:code([a-z]+).html',
        name: 'plan',
        component: () => import('views/Plan'),
        meta: {
          title: '解决方案'
        }
      },
      {
        path: 'contact.html',
        name: 'contact',
        component: () => import('views/Contact'),
        meta: {
          title: '联系我们'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
